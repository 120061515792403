import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import PostGrid from '../components/postgrid'
import { ITag } from '../components/taglist'

interface ITutorialPageProps {
    data: {
        allGhostPost: {
            posts: {
                post: {
                    id: string
                    title: string
                    slug: string
                    excerpt: string
                    primary_author: {
                        name: string
                        slug: string
                    }
                    published_at: string
                    feature_image: string
                    feature_image_alt: string
                    tags: ITag[]
                }
            }[]
        }
    }
}

export default function ArticlesPage({ data }: ITutorialPageProps) {
    const posts = data.allGhostPost.posts;

    return (
        <Layout>
            <Helmet title="All Articles" />
            <h1 className="text-4xl font-bold mb-8">All Articles</h1>
            <PostGrid posts={posts} featureTopPost={false} />
        </Layout>
    )
}

export const postQuery = graphql`
    query {
        allGhostPost(
            filter: {tags: {elemMatch: {name: {eq: "#tutorial"}}}}
            sort: { order: DESC, fields: [published_at] }
            ) {
            posts: edges {
                post: node {
                    id
                    title
                    slug
                    excerpt
                    published_at(formatString: "MMMM DD, YYYY")
                    feature_image
                    feature_image_alt
                    primary_author {
                        name
                        slug
                    }
                    tags {
                        name
                        slug
                        visibility
                    }
                }
            }
        }
    }
`